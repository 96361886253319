<script lang="ts" setup>
import navItems from '@/navigation/vertical'

// Components
import Footer from '@/layouts/components/Footer.vue'
import NavBarNotifications from '@/layouts/components/NavBarNotifications.vue'
import NavSearchBar from '@/layouts/components/NavSearchBar.vue'
import Reload from '@/layouts/components/Reload.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'

// @layouts plugin
import { VerticalNavLayout } from '@layouts'

const isDrawerVisible = ref(false)
const userData = useCookie<any>('userData')
</script>

<template>
  <VerticalNavLayout :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <IconBtn
          id="vertical-nav-toggle-btn"
          class="ms-n3 d-lg-none"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon
            size="26"
            icon="tabler-menu-2"
          />
        </IconBtn>

        <VSpacer class="d-lg-none" />
        <Reload />
        <NavSearchBar
          v-if="userData && userData.role !== 'owner'"
          class="ms-lg-n3"
        />
        <VSpacer v-else />

        <NuxtLink
          v-if="userData && userData.role !== 'admin'"
          :to="{ name: userData.role === 'client' ? 'clients-calendar' : 'owners-calendar' }"
        >
          <IconBtn>
            <VIcon
              size="26"
              icon="tabler-calendar"
            />
          </IconBtn>
        </NuxtLink>

        <NavBarNotifications class="me-2" />
        <UserProfile v-model:isDrawerOpen="isDrawerVisible" />
      </div>
    </template>

    <!-- 👉 Pages -->
    <slot />

    <!-- 👉 Footer -->
    <template #footer>
      <Footer />
    </template>

    <TheCustomizer v-model:isDrawerOpen="isDrawerVisible" />
  </VerticalNavLayout>
</template>
